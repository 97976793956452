/* External Libraries */
import dynamic from 'next/dynamic';
/* Components & Widgets */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { getActiveActor } from '@/src/lib/user-client-wrapper';
import { compare_year_month } from '@/src/lib/utils/sort';
const CRMBoardCountChart = dynamic(() => import(`./crm-board-count-chart`), {
  ssr: false
});

export default function BoardCounter() {
  const [data, setData] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const router: any = useRouter();
  const { t } = useTranslation('common');

  const activeActor = getActiveActor();

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });

  useEffect(() => {
    const body = JSON.stringify({
      actor_uuid: activeActor.actor_uuid
    });

    fetch(`/api/crm/get-board-count`, {
      method: 'POST',
      body
    })
      .then((res) => {
        return res?.json();
      })
      .then((json) => {
        const dataArray: any[] = [];
        let total = 0;

        const tmp: any[] = compare_year_month(json);

        for (let i = 0; i < tmp.length; i++) {
          const dataSet = tmp[i];
          total += dataSet.board_count;
          dataArray.push({ x: dataSet.year_month, y: total });
        }

        setData([
          {
            id: 'board_count',
            color: 'hsl(239, 70%, 50%)',
            data: dataArray
          }
        ]);
        setTotalCount(total);
      })
      .catch((e) => {
        console.error(`Failed to fetch data`, e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <div className="relative rounded-md w-full flex flex-col items-start justify-start h-[120px]">
          <div className="w-full mb-5 text-xs font-demi">{t('general.loading')}</div>
          <div className="w-full font-medium text-4xl"></div>
          <div className="w-full text-[11px] text-gray-400"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="relative rounded-md w-full flex flex-col lg:flex-row items-start justify-between lg:h-[120px] md:space-x-3">
        <div className="flex justify-between flex-col h-full lg:w-1/2">
          <div className="w-full text-xs font-demi">{t('crm.board-count')}</div>
          <div className="w-full flex-1 flex items-end font-medium text-4xl">{totalCount}</div>
          <div className="w-full hidden md:block text-[11px] leading-[11px] text-gray-400">
            {t('crm.board-count-description')}
          </div>
        </div>
        <div className="w-full lg:w-1/2 h-[100px] lg:h-[120px] flex items-end">
          <div className="w-full h-[80px] flex justify-end">
            {data && data?.length > 0 && data[0]?.data?.length > 0 ? (
              <CRMBoardCountChart data={data} />
            ) : (
              <div className="text-gray-400 dark:text-gray-300 italic text-xs">
                {t('general.no-boards')}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
