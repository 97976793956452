import { GeoJSONSource, MapLayerMouseEvent } from 'mapbox-gl';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Map, { Layer, MapRef, Popup, Source } from 'react-map-gl';

interface Property {
  lng: number;
  lat: number;
  uuid: string;
  custom_name: string;
  property_key: string;
  area_total: number;
  county: string;
}

interface DashboardMapProps {
  propertyList: Property[];
}

export default function DashboardMap({ propertyList }: DashboardMapProps) {
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const mapRef = useRef<MapRef>(null); // Reference to the Map component

  // Create a geojson feature for each property
  const geojsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: propertyList.map((property) => ({
        type: 'Feature',
        properties: { ...property },
        geometry: {
          type: 'Point',
          coordinates: [property.lng, property.lat]
        }
      }))
    };
  }, [propertyList]);

  // Handle cluster click to zoom in and show its children
  const handleClusterClick = (e: MapLayerMouseEvent) => {
    const clusterId = e.features?.[0]?.properties?.cluster_id;
    const mapboxSource = mapRef.current?.getSource('properties') as GeoJSONSource;

    if (mapboxSource && clusterId !== undefined) {
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }
        mapRef.current?.easeTo({
          center: (e.features?.[0].geometry as GeoJSON.Point).coordinates as [number, number],
          zoom: zoom,
          duration: 500
        });
      });
    }
  };

  // Handle individual property marker click to show popup
  const handlePropertyClick = (e: MapLayerMouseEvent) => {
    const clickedFeature = e.features?.[0];
    const property = clickedFeature?.properties;

    if (property) {
      const selected = propertyList.find((p) => p.uuid === property.uuid);
      if (selected) {
        setSelectedProperty(selected);
      }
    }
  };

  // Use Map's event handlers to manage clicks for clusters and markers
  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.on('click', 'clusters', handleClusterClick); // Cluster clicks
    mapRef.current.on('click', 'unclustered-point', handlePropertyClick); // Property marker clicks

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', 'clusters', handleClusterClick);
        mapRef.current.off('click', 'unclustered-point', handlePropertyClick);
      }
    };
  }, [propertyList]);

  return (
    <div className="w-full h-[400px] rounded-t-md">
      <Map
        ref={mapRef}
        mapboxAccessToken={`pk.eyJ1IjoiYm9ib2xhZ2V0IiwiYSI6ImNrZmNmZmtlajE4NG0zNm81NjB5ZTgwOGwifQ.AqhIm23pQPp2jjs_Q2Ca6Q`}
        initialViewState={{
          longitude: propertyList[0]?.lng || -122.4, // Default to first property or fallback
          latitude: propertyList[0]?.lat || 37.8,
          zoom: 4
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={`mapbox://styles/bobolaget/cm11shb1501de01qo0pdqh21a`}>
        {/* Source to manage clustering */}
        <Source
          id="properties"
          type="geojson"
          data={geojsonData}
          cluster={true}
          clusterMaxZoom={14} // Max zoom to cluster points
          clusterRadius={50} // Cluster radius in pixels
        >
          {/* Layer for clusters */}
          <Layer
            id="clusters"
            type="circle"
            source="properties"
            filter={['has', 'point_count']}
            paint={{
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6', // Blue for small clusters
                100,
                '#f1f075', // Yellow for medium clusters
                750,
                '#f28cb1' // Pink for large clusters
              ],
              'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
            }}
          />

          {/* Layer for cluster count labels */}
          <Layer
            id="cluster-count"
            type="symbol"
            source="properties"
            filter={['has', 'point_count']}
            layout={{
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12
            }}
          />

          {/* Layer for individual property markers */}
          <Layer
            id="unclustered-point"
            type="circle"
            source="properties"
            filter={['!', ['has', 'point_count']]}
            paint={{
              'circle-color': '#11b4da', // Distinct blue color for properties
              'circle-radius': 10,
              'circle-stroke-width': 2,
              'circle-stroke-color': '#fff' // White border for property markers
            }}
          />
        </Source>

        {/* Popup for selected property */}
        {selectedProperty && (
          <Popup
            longitude={selectedProperty.lng}
            latitude={selectedProperty.lat}
            anchor="top"
            onClose={() => setSelectedProperty(null)}
            closeOnClick={true}>
            <div>
              <h3>{selectedProperty.custom_name}</h3>
              <p>Property Key: {selectedProperty.property_key}</p>
              <p>Area: {selectedProperty.area_total} sqm</p>
              <p>County: {selectedProperty.county}</p>
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
}
