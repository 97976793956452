/* External Libraries */
import dynamic from 'next/dynamic';
/* Components & Widgets */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Actor } from 'shared/dist/types';

import { Scooter } from '@/src/animations';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';
import { getActiveActor } from '@/src/lib/user-client-wrapper';
import { compare_year_month } from '@/src/lib/utils/sort';
const CRMActivityChart = dynamic(() => import(`./crm-activity-chart`), {
  ssr: false
});

export default function CRMActivity() {
  const [data, setData] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const router: any = useRouter();
  const { t } = useTranslation('common');
  const activeActor = getActiveActor();

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });

  useEffect(() => {
    const body = JSON.stringify({
      actor_uuid: activeActor.actor_uuid
    });

    fetch(`/api/crm/get-board-property-activities`, {
      method: 'POST',
      body
    })
      .then((res) => {
        return res?.json();
      })
      .then((json) => {
        const dataArray: any[] = [];
        let total = 0;

        const tmp: any[] = compare_year_month(json);

        for (let i = 0; i < tmp.length; i++) {
          const dataSet = tmp[i];
          if (dataSet.year_month) {
            total += dataSet.prop_count ?? 0;
            dataArray.push({ x: dataSet.year_month, y: total });
          }
        }

        setData([
          {
            id: 'board_count',
            color: 'hsl(239, 70%, 50%)',
            data: dataArray
          }
        ]);
      })
      .catch((e) => {
        console.error(`Failed to fetch data`, e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <div className="relative rounded-md w-full flex flex-col items-start justify-start h-[120px]">
          <div className="w-full mb-5 text-xs font-demi">{t('general.loading')}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="relative rounded-md w-full flex flex-col items-start justify-between h-[120px]">
        <div className="w-full text-xs font-demi">
          {t('crm.board-activity', { companyName: activeActor.company_name })}
        </div>
        <div className="w-full h-[90px]">
          {data && data?.length > 0 && data[0]?.data?.length > 0 ? (
            <CRMActivityChart data={data} />
          ) : (
            <div className="text-gray-400 dark:text-gray-300 italic text-xs">
              {t('general.no-boards')}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
